/*
###############################################################################
##
## @file:         _mixins.scss
## @description:  Defines custom mixins
##
###############################################################################
*/

/**
 * Breakpoint
 *
 * Usage:
 * div { width: 75%;
 *   @include bp($tablet_narrow, max) { width: 50%; }
 * }
 *
 * Result:
 * div { width: 75%; }
 * @media (max-width: 37.438em) {
 *   div { width: 50%; }
 * }
 * ------------------------------------------------------------------------- */

@mixin bp($value-px, $min-max: 'min') {
  @if $min-max == 'min' {
    $value-em: $value-px / 16px * 1em;
    @media (min-width: $value-em) {
      @content;
    }
  } @else if $min-max == 'max' {
    $value-em: ($value-px - 1) / 16px * 1em;
    @media (max-width: $value-em) {
      @content;
    }
  }
}


/**
 * REM with px fallback for all properties
 *
 * @author drublic
 * https://github.com/drublic/Sass-Mixins/blob/master/partials/_rem.scss
 *
 * Usage:
 * div { @include x-rem(margin, 10, 20, 30, 40); }
 *
 * Result:
 * div {
 *   margin: 160px 320px 480px 640px;
 *   margin: 10rem 20rem 30rem 40rem;
 * }
 *
 * Note: Depending on the font-size of the root-element `rem` calculates a
 * property of a current element. The fallback solution excepts a
 * `default-font-size`-argument which is 16px by default.
 * ------------------------------------------------------------------------- */
@mixin x-rem ($property, $values, $main-font-size: 16px) {
  // Empty list for all values in px
  $px-values: ();
  $rem-values: ();

  // Iterate over entries
  @each $value in $values {
    // If the value is zero, return 0
    @if $value == 0 or type_of($value) != number {
            $px-values: append($px-values, $value);
            $rem-values: append($rem-values, $value);

    // If the value is not zero, convert it from px to rem
    } @else {
            $px-values: append($px-values, ($value * $main-font-size) );
            $rem-values: append($rem-values, #{$value});
    }
  }

  // Return the property and its list of converted values
  #{$property}: #{$px-values};
  #{$property}: #{$rem-values}rem;
}


/**
 * Input placeholder
 *
 * Usage:
 * input {
 *   @include placeholder {
 *     color: #fff;
 *   }
 * }
 * ------------------------------------------------------------------------- */
@mixin placeholder {
    &.placeholder { @content; }
    &::placeholder { @content; }
}


/**
 * Min-height / min-width
 *
 * Usage:
 * div { @include x-min-size(height, 100%) }
 * ------------------------------------------------------------------------- */
@mixin x-min-size ($property, $value) {
  #{$property}: auto !important;
  #{$property}: #{$value};
  min-#{$property}: #{$value};
}


/**
 * Background transparency with fallback
 *
 * Usage:
 * div { @include x-bg-opacity(#333, 0.5); }
 * ------------------------------------------------------------------------- */
@mixin x-bg-opacity($color, $opacity: 0.5) {
    background: $color;
    background: rgba($color, $opacity);
}


/**
 * Icon replacement
 *
 * Usage:
 * .replace-with-icon { @include x-icon($icon-locked); color: #fff; ... }
 * ------------------------------------------------------------------------- */
@mixin x-icon($icon: "") {
  font-family: $font-icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  content: $icon;
  text-indent: 0;
}

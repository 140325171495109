/*
###############################################################################
##
## @file:         inc.globalstyling.less
## @description:  Defines the global styling for the SITENAME website
##
###############################################################################


[Table of contents]

1. GLOBAL STYLING
    1.1 general
    1.2 headings
    1.3 links & buttons
    1.4 text styles
    1.5 lists
    1.6 tables
    1.7 icons
    1.8 extra

###############################################################################
*/


/* VIEWPORT: will replace the metatag in the future */
@viewport               { zoom: 1.0; width: extend-to-zoom; }
@-ms-viewport           { zoom: 1.0; width: extend-to-zoom; }


/* =========================================================================
 * 1. GLOBAL STYLING
 * ========================================================================= */

/* 1.1 general
---------------------------------------------------------------------------- */
html                    { box-sizing: border-box; }
*, *:before,
*:after                 { box-sizing: inherit; }

body                    { background-color: $color-bg-default; font: 100% $font-regular; color: $color-default; text-align: center; line-height: 1.4; }
#wrapper                { text-align: left; }


/* 1.2 headings
---------------------------------------------------------------------------- */
h1,h2,h3,h4             { font-weight: normal; color: $colorBlue; }
h1                      { font-size: 1.7em; margin: 0 0 1em 0; }
h2                      { font-size: 1.5em; margin: 0 0 0.7em 0; }
h3                      { font-size: 1.2em; margin: 0 0 0.5em 0; }
h4                      { font-size: 1em; margin: 0.5em 0 0.3em 0; }


/* 1.3 links & buttons
---------------------------------------------------------------------------- */
a                       { text-decoration: underline; white-space: nowrap; color: $colorHighlight; }
a:hover                 { color: $colorDefault; cursor: pointer; }
  #page a.active,
  #page a:hover         { text-decoration: none; }

  a:active,
  a:selected            { border: none; outline: none; }


/* 1.4 text styles
---------------------------------------------------------------------------- */
p                       { margin: 0; padding-bottom: 10px; }
strong                  { font-weight: bold; }
em                      { font-style: italic; }


/* 1.5 lists
---------------------------------------------------------------------------- */
.page-content ul,
.page-content ol              { margin: 0; padding-bottom: 10px; }
.page-content li              { list-style: disc; margin: 0 0 5px 14px; }

.page-content ol > li         { list-style: decimal; list-style-position: outside; }


/* 1.6 tables
---------------------------------------------------------------------------- */
tbody                         { border: none; }


/* 1.7 icons
---------------------------------------------------------------------------- */
.loadSmall                    { background-image: url(../img/throbber_9x9.gif); background-repeat: no-repeat; background-position: 50% 50%; }
.loadMedium                   { background-image: url(../img/throbber_20x20.gif); background-repeat: no-repeat; background-position: 50% 50%; }
.loadBig                      { background-image: url(../img/throbber_32x32.gif); background-repeat: no-repeat; background-position: 50% 50%; }


/* 1.8 extra
---------------------------------------------------------------------------- */

/* vertical alignment tool */
div.valignContainer         { display: table; overflow: hidden; width: 990px; height: 490px; }
div.valignMiddle            { display: table-cell; vertical-align: middle; }

/* anti Skype */
body span.skype_pnh_container                 { display: none !important; }
body span.skype_pnh_print_container,
body span[class^="skype_pnh_print_container"] { display: inline !important; }

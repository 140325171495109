/*
###############################################################################
##
## @file:         inc.generalelements.less
## @description:  Defines the general elements styling for the SITENAME website
##
###############################################################################
*/


/**
 * Header
 * -------------------------------------------------------------------------- */
/* #header h1                        { margin: 0; height: 120px; }
#header h1 a                      { position: absolute; top: 0; left: -120px; width: 400px; height: 140px; background: url(../img/imgLogoSmall.jpg) no-repeat 0 0; display: block; text-indent: 999px; overflow: hidden; }

#header h2                        { display: none; }

@include bp($mobile-wide) {
  #header h1                      { height: 170px; }
  #header h1 a                    { left: -219px; width: 700px; height: 337px; background: url(../img/imgLogo.jpg) no-repeat 0 0; }
} */


/* 1.2 header block
---------------------------------------------------------------------------- */
/* #headerBlock                                            { position: relative; padding: 10px 0 20px 0; z-index: 999; }
#headerBlock .block                                     { font-size: 0.813em; }
#headerBlock .content,
#headerBlock .content a                                 { color: #fff; text-decoration: none; } */


/* @include bp($tablet-wide) {
  #headerBlock                                          { position: absolute; top: 40px; right: 0; width: 35%; }
} */

/* general */
.page-header                  {
  &__top                        { background-color: $color-bg-default; color: $color-midgrey;
    .wrapper                      { text-align: right; }
  }
  &__content                    { background-color: $color-brand-blue-regular; color: $color-white; min-height: 60px;
    @include bp($tablet-narrow)   { min-height: 80px; }
  }
}

/* logo */
.branding-logo                { z-index: 2; position: absolute; top: 0; left: -30px; display: block; width: 270px; height: 60px;
  &__img                        { display: block; width: 100%; height: auto; }
  @include bp($tablet-narrow)   { width: 360px; height: 80px; }
}

/* guest counter */
.block-m4m-guestcounter       { display: none; vertical-align: top; margin-right: 10px; line-height: 34px; text-align: center; font-size: .75em;
  @include bp($mobile-wide)     { display: inline-block; }
}

/* login/out */
.block-m4m-loginout           { display: inline-block; vertical-align: top; margin-right: 10px; text-align: center;
  .lnkLogin,
  .lnkAccount                   { position: relative; display: block; overflow: hidden; width: 40px; height: 34px; text-indent: -999em; background-color: $color-brand-blue-regular;
    &:hover,
    &:focus                       { background-color: $color-brand-blue-var1; }
    &:after                       { @include x-icon($icon-locked); position: absolute; top: 0; left: 0; width: 40px; height: 34px; line-height: 34px; color: $color-white; }
  }
  .lnkAccount:after             { content: $icon-profile; }
  .lnkLogout                    { display: none; }
}

/* search block */
.block-m4m-search-headersearch{ display: inline-block; vertical-align: top; margin-right: .25em; }

/* search page */
#block-views-exp-search-page                            { clear: both; padding-top: 1%; }


/* 1.3 content
---------------------------------------------------------------------------- */
.page-content                 { @include x-rem(font-size, .938); }

#contentBottom .block h2                                { margin: 0; padding: 15px 0 15px 20px; background: $colorBlueDark; color: #fff; font-weight: normal; }
#contentBottom .block .content ul,
#contentBottom .block .content li                       { margin: 0; padding: 0; list-style: none; line-height: 1.1em; }
#contentBottom .block .content ul                       { border: solid 1px $colorGreyLight; }
#contentBottom .block .content .row1                    { background: $colorGreyLight; }
#contentBottom .block .content .row2                    {  }
#contentBottom .block .content li a                     { display: block; padding: 10px 0 10px 20px; text-decoration: none; color: $colorDefault; font-weight: bold; font-size: 0.813em; white-space: normal; }
  #contentBottom .block .content li a:hover             { color: $colorBlueHover; }
#contentBottom .block .content li .info                 { color: $colorBlue; font-weight: normal; }

/* pages block */
/* #block-views-pages-block .content a,
#block-views-pages-block-1 .content a                   { padding: 19px 0 19px 20px !important; } */

@include bp($tablet-wide) {
  #contentBottom .block                                 { float: left; width: 32%; margin-right: 2%; }
  #contentBottom div:last-child.block                   { margin-right: 0; }
}


/* 1.4 footer elements
---------------------------------------------------------------------------- */
#footer                                                 { font-size: 0.813em; line-height: 1.5em; }
#footer,
#footer a                                               { color: #0c8ab8; text-decoration: none; }
  #footer a:hover                                       { text-decoration: underline; }

#footer .left                                           { font-weight: bold; }


/* 1.5 links
---------------------------------------------------------------------------- */
.links,
.links li                                               { margin: 0 !important; padding: 0 !important; }
.links li                                               { display: inline-block; margin-right: 10px !important; }

/* main links */
#block-system-main .links                               { margin-top: 30px !important; padding-top: 10px !important; border-top: solid 1px $colorGreyLight2; }

#block-system-main .links .share-facebook a,
#block-system-main .links .print_html a,
#block-system-main .links .print_mail a,
#block-system-main .links .print_pdf a                  { display: inline-block; width: 32px; height: 32px; background: url(../img/spriteIcons.png) no-repeat 0 0; text-indent: 999px; overflow: hidden; }

#block-system-main .links .share-facebook a             { background-position: 0 -500px; }
#block-system-main .links .print_html a                 { background-position: 0 -600px; }
#block-system-main .links .print_mail a                 { background-position: 0 -700px; }
#block-system-main .links .print_pdf a                  { background-position: 0 -800px; }


/* 1.6 main system block
---------------------------------------------------------------------------- */
#block-system-main                                      { padding-bottom: 30px; }
#block-system-main img                                  { max-width: 100%; height: auto; }

.page-title                                             { font-family: $font-narrow; font-size: 1.5em; }


/* 1.7 comments
---------------------------------------------------------------------------- */
.comment                                                { margin: 20px 0 0 0; padding: 20px; border: solid 1px $colorGreyLight2; }
.comment .submitted                                     { padding: 0 0 10px 0; color: $colorBlue; font-size: 0.813em; }
.comment .author                                        { font-size: 1.5em; margin: 10px 0 0 0; }
.comment .author a                                      { text-decoration: none; white-space: normal; }

/* comment links */
.comment .links                                         { padding: 10px 0 0 0 !important; float: right; }
.comment .links li                                      { clear: both; list-style: none; padding: 0; margin: 0 0 0 5px !important; font-size: 0.813em; }
.comment .links li a                                    { display: inline-block; padding: 1px 5px; background: $colorGreyLight2; color: $colorDefault; text-decoration: none;  }
  .comment .links li a:hover                            { background: $colorBlueHover; text-decoration: none; color: #fff; }

@include bp($tablet-wide) {
  .comment                                              { padding: 0; border: none; }

  /* submitted */
  .comment .submitted                                   { float: left; width: 20%; padding-right: 20px; }
  .comment .content                                     { position: relative; float: left; width: 80%; padding: 20px; border: solid 1px $colorGreyLight2; background: #fff; }
  .comment .content:after                               { content: ' '; position: absolute; top: 10px; left: -18px; display: block; width: 20px; height: 40px; background: url(../img/spriteIcons.png) no-repeat 0 -900px; }
}


/* 1.8 search results
---------------------------------------------------------------------------- */
.view-id-search                                         { margin-top: 30px; }
#lstSearchResults,
#lstSearchResults li                                    { margin: 0; padding: 0; list-style: none; }
#lstSearchResults                                       { margin-top: 30px !important; }


/* 1.9 attachments
---------------------------------------------------------------------------- */
#attachments                                            { margin-top: 30px; }
#attachments table                                      { width: 100%; }
#attachments th,
#attachments td                                         { padding: 10px; }


/* 1.11 premium content - no access
---------------------------------------------------------------------------- */
.premium-active               {
  .toc-target                   { position: relative;
    &:before                      { content: ""; display: block; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: rgba(255,255,255,.75); background: linear-gradient(to bottom, rgba(white,0.001) 0%, rgba(white,0.5) 50%, rgba(white,1) 100%); }
  }
}
/* ----- Premium content message ----- */
.nopremium-message            { position: absolute; top: 20%; width: 80%; left: 10%; z-index: 10; color: #fff; margin-bottom: 2em; padding: 1em; padding-left: 65px; background: $colorBlue url(../img/lock.svg) no-repeat 10px 50%; background-size: 40px; line-height: 2em;
  p                             { padding: 0; text-align: center; }
  a                             { color: #fff; font-weight: bold;
    &:hover, &:focus              { color: $colorDefault; }
  }
}

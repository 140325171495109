/*
###############################################################################
##
## @file:         inc.modifiers.scss
## @description:  Defines the modifiers for the website
##
###############################################################################


[Table of contents]

1. MODIFIERS

###############################################################################
*/

/* =========================================================================
 * 1. MODIFIERS
 * ========================================================================= */
#page .noul             { text-decoration: none; }
  #page a.noul:hover    { text-decoration: underline; }
#page .nmb              { margin-bottom: 0; }
#page .nmr              { margin-right: 0; }
#page .nmt              { margin-top: 0; }
#page .npr              { padding-right: 0; }
#page .tal              { text-align: left; }
#page .tac              { text-align: center; }
#page .tar              { text-align: right; }
#page .ul               { text-decoration: underline; }
#page .lcase            { text-transform: none; }
#page .flr              { float: right; }
#page .fll              { float: left; }
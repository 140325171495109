/*
###############################################################################
##
## @file:         inc.fonts.less
## @description:  Defines the fonts for the website
##
###############################################################################
*/

/* @font-face {
    font-family: 'medicons';
    src:url('../fonts/icomoon.eot?s2q859');
    src:url('../fonts/icomoon.eot?#iefixs2q859') format('embedded-opentype'),
        url('../fonts/icomoon.woff?s2q859') format('woff'),
        url('../fonts/icomoon.ttf?s2q859') format('truetype'),
        url('../fonts/icomoon.svg?s2q859#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
} */
@font-face {
  font-family: 'icomoon';
  src:  url('#{$icomoon-font-path}/icomoon.eot?pm39b1');
  src:  url('#{$icomoon-font-path}/icomoon.eot?pm39b1#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/icomoon.woff2?pm39b1') format('woff2'),
    url('#{$icomoon-font-path}/icomoon.ttf?pm39b1') format('truetype'),
    url('#{$icomoon-font-path}/icomoon.woff?pm39b1') format('woff'),
    url('#{$icomoon-font-path}/icomoon.svg?pm39b1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}


$font-regular: "Roboto", Arial, Helvetica, Verdana, sans-serif;
$font-narrow: "Roboto Condensed", Arial, Helvetica, Verdana, sans-serif;
$font-icons: "icomoon" !important;

$fontRegular: $font-regular;
$fontMedicons: $font-icons;

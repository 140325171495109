/*
###############################################################################
##
## @file:         inc.navigation.less
## @description:  Defines the navigation styling for the SITENAME website
##
###############################################################################
*/

/* 1.1 main menu
---------------------------------------------------------------------------- */
.nav-main                     { z-index: 1;
  /* layout */
  .content                      { position: relative; padding-top: 60px; }
  @include bp($tablet-narrow)   {
    .content                      {padding-top: 80px; }
  }
  @include bp($tablet-wide)     { margin-left: 380px;
    .content                      { padding-top: 20px; }
  }

  /* toggle link */
  .menu-toggle                  { position: absolute; top: 10px; right: 10px; display: block; width: 40px; height: 40px; overflow: hidden; text-indent: -999em; text-decoration: none;
    &:after                       { @include x-icon($icon-menu); position: absolute; top: 0; left: 0; width: 40px; height: 40px; line-height: 40px; color: $color-white; text-align: center; @include x-rem(font-size, 1.25); }
    &:hover,
    &:focus                       { background-color: $color-white;
      &:after                       { color: $color-brand-blue-var1; }
    }
    @include bp($tablet-narrow)   { top: 20px; }
    @include bp($tablet-wide)     { display: none; }
  }
  &.menu-visible .menu-toggle   { background-color: $color-brand-blue-var1;
    &:after                       { content: $icon-close; color: $color-white; }
  }

  /* show/hide menu */
  .menu-wrapper                 { position: relative; margin: 0 -2%; max-height: 0px; overflow: hidden; border-top: 1px solid $color-white; background-color: $color-brand-blue-regular; transition: max-height .5s ease;
    @include bp($tablet-wide)     { height: 100%; margin: 0; max-height: none; overflow: visible; border-top: 0 none; }
  }
  &.menu-visible .menu-wrapper  { max-height: 500px; }

  /* menu styling */
  .menu                         {
    &, li                         { display: block; margin: 0; padding: 0; list-style: none; }
    li                            { border-bottom: 1px solid $color-white; }
    a                             { display: block; padding: 10px; line-height: 20px; @include x-rem(font-size, .938); font-weight: 400; font-family: $font-narrow; text-transform: uppercase; color: $color-white; text-decoration: none; text-align: center; transition: background-color .5s ease;
      &:hover,
      &:focus,
      &.active                      { background-color: $color-brand-blue-var1; }
    }

    @include bp($tablet-wide)     { text-align: right;
      li                            { display: inline-block; border: 0 none; }
    }
  }
}


/* 1.2 medical menu
---------------------------------------------------------------------------- */
#medicalNavWrapper .content                                           { position: relative; /* needed for IE */ }
#medicalNavWrapper .content ul,
#medicalNavWrapper .content li                                        { padding: 0; margin: 0; list-style: none; }

#medicalNavWrapper .links                                             { display: table; position: relative; width: 100%; }
#medicalNavWrapper .links a,
#medicalNavWrapper .links .icon                                       { display: table-cell; vertical-align: bottom; padding: 10px; text-decoration: none; color: #fff; white-space: normal; }
#medicalNavWrapper .links a                                           { width: 80%; padding-right: 0; }
#medicalNavWrapper .links .icon                                       { width: 20%; text-align: center; font-size: 2em; }
#medicalNavWrapper .links:hover                                       { cursor: pointer; }

#medicalNavWrapper .content .menu                                     { overflow: hidden; }

/* level 1 */
#medicalNavWrapper .content > .menu > li                              { margin: 0 0 2% 0; }

#medicalNavWrapper .content > .menu > li > .links                                            { background: $colorBlue; }
#medicalNavWrapper .content > .menu > li > .links:hover                                      { background: $colorBlueHover; }

#medicalNavWrapper .content > .menu > li.togglerTargetVisible > .links > a,
#medicalNavWrapper .content > .menu > li.togglerTargetVisible > .links > .icon               { background: $colorBlueDark; }

/* level 2 */
#medicalNavWrapper .content > .menu > li > .menu                                             { display: none; padding: 10px 20px; background: $colorBlueDark; }
#medicalNavWrapper .content > .menu > li > .menu > li .links > a,
#medicalNavWrapper .content > .menu > li > .menu > li .links > .icon                         { padding: 15px 10px; }

#medicalNavWrapper .content > .menu > li > .menu > li > .links:hover                         { background: $colorBlueDarkHover; }
#medicalNavWrapper .content > .menu > li > .menu > li.togglerTargetVisible > .links > a,
#medicalNavWrapper .content > .menu > li > .menu > li.togglerTargetVisible > .links > .icon  { background: $colorBlueDarker; }

/* level 3 */
#medicalNavWrapper .content > .menu > li > .menu > li > .menu                                { display: none; padding: 10px 20px; background: $colorBlueDarker; }

#medicalNavWrapper .content > .menu > li > .menu > li > .menu > li > .links:hover            { background: $colorBlueDarkerHover; }

/* ---------- screen width 2cols ---------- */
@include bp($mobile-wide) {
  /* --- level 1 --- */
  #medicalNavWrapper .content > .menu > li                            { float: left; width: 49%; height: 49%; margin: 0 2% 2% 0; }
  #medicalNavWrapper .content > .menu > li > .links > a,
  #medicalNavWrapper .content > .menu > li > .links > .icon           { box-sizing: content-box; height: 100px; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li:nth-child(2n-1)            { clear: left; }
  /* reset the right margin of the last col */
  #medicalNavWrapper .content > .menu > li:nth-child(2n+2)            { margin-right: 0; }

  #medicalNavWrapper .content > .menu > li.togglerTargetVisible > .links > a,
  #medicalNavWrapper .content > .menu > li.togglerTargetVisible > .links > .icon { padding-bottom: 10%; }
}

/* ---------- screen width 3cols ---------- */
@include bp($tablet-narrow) {
  /* --- level 1 --- */
  #medicalNavWrapper .content > .menu > li                            { width: 32%; height: 32%; margin: 0 2% 2% 0; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li:nth-child(2n-1)            { clear: none; }
  #medicalNavWrapper .content > .menu > li:nth-child(3n-2)            { clear: left; }
  /* reset the right margin of the last col */
  #medicalNavWrapper .content > .menu > li:nth-child(2n+2)            { margin-right: 2%; }
  #medicalNavWrapper .content > .menu > li:nth-child(3n+3)            { margin-right: 0; }

  /* --- level 2 & 3 --- */
  #medicalNavWrapper .content > .menu > li > .menu > li,
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li  { float: left; width: 50%; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li > .menu > li:nth-child(2n-1),
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li:nth-child(2n-1) { clear: left; }
}

/* ---------- screen width 4cols ---------- */
@include bp($tablet-wide) {
  /* --- level 1 --- */
  #medicalNavWrapper .content > .menu > li,
  #medicalNavWrapper .content > .menu > li:nth-child(2n+2)            { width: 24.25%; height: 24.25%; margin: 0 1% 1% 0; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li:nth-child(3n-2)            { clear: none; }
  #medicalNavWrapper .content > .menu > li:nth-child(4n-3)            { clear: left; }
  /* reset the right margin of the last col */
  #medicalNavWrapper .content > .menu > li:nth-child(3n+3)            { margin-right: 1%; }
  #medicalNavWrapper .content > .menu > li:nth-child(4n+4)            { margin-right: 0; }

  /* --- level 2 & 3 --- */
  #medicalNavWrapper .content > .menu > li > .menu > li,
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li  { width: 33.3333%; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li > .menu > li:nth-child(2n-1),
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li:nth-child(2n-1) { clear: none; }
  #medicalNavWrapper .content > .menu > li > .menu > li:nth-child(3n-2),
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li:nth-child(3n-2) { clear: left; }
}

/* ---------- screen width 5cols ---------- */
@include bp($desktop) {
  /* --- level 1 --- */
  #medicalNavWrapper .content > .menu > li,
  #medicalNavWrapper .content > .menu > li:nth-child(2n+2),
  #medicalNavWrapper .content > .menu > li:nth-child(3n+3)            { width: 19.2%; height: 19.2%; margin: 0 1% 1% 0; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li:nth-child(4n-3)            { clear: none; }
  #medicalNavWrapper .content > .menu > li:nth-child(5n-4)            { clear: left; }
  /* reset the right margin of the last col */
  #medicalNavWrapper .content > .menu > li:nth-child(4n+4)            { margin-right: 1%; }
  #medicalNavWrapper .content > .menu > li:nth-child(5n+5)            { margin-right: 0; }

  /* --- level 2 & 3 --- */
  #medicalNavWrapper .content > .menu > li > .menu > li,
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li  { width: 25%; }
  /* clear the first col */
  #medicalNavWrapper .content > .menu > li > .menu > li:nth-child(3n-2),
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li:nth-child(3n-2) { clear: none; }
  #medicalNavWrapper .content > .menu > li > .menu > li:nth-child(4n-3),
  #medicalNavWrapper .content > .menu > li > .menu > li > .menu > li:nth-child(4n-3) { clear: left; }
}

/* ---------- screen width 2cols & up: bg-icons ---------- */
@include bp($mobile-wide) {
  #medicalNavWrapper .content > .menu > li  {
    & > .links:before                         { content: ""; display: block; position: absolute; width: 80px; height: 80px; top: 15px; right: 15px; opacity: 0.4; color: #fff; text-align: center;
                                                font-family: $fontMedicons; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 80px; font-size: 80px;
                                                -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
    & > .links:hover:before,
    &.togglerTargetVisible > .links:before    { opacity: 0.6; }

    &.menu-mlid-699 > .links:before           { content: "\e60f"; } /* Symptomen */
    &.menu-mlid-700 > .links:before           { content: "\e608"; } /* Med. Analysen */
    &.menu-mlid-701 > .links:before           { content: "\e601" } /* Adv. Life Support */
    &.menu-mlid-702 > .links:before           { /* content: "\e611"; */ content: "\e600"; } /* Traumatologie */
    &.menu-mlid-703 > .links:before           { content: "\e603"; } /* Cardiologie */
    &.menu-mlid-704 > .links:before           { content: "\e60a"; } /* Neurologie */
    &.menu-mlid-705 > .links:before           { content: "\e606"; } /* Infectie ziekten */
    &.menu-mlid-706 > .links:before           { content: "\e60d"; } /* Pneumologie */
    &.menu-mlid-707 > .links:before           { content: "\e609"; } /* Metabole ziekten */
    &.menu-mlid-708 > .links:before           { content: "\e60c"; } /* Pediatrie */
    &.menu-mlid-709 > .links:before           { content: "\e605"; } /* Gynaecologie */
    &.menu-mlid-710 > .links:before           { content: "\e607"; } /* Medicatie */
    &.menu-mlid-711 > .links:before           { content: "\e60e"; } /* Rampentmngt */
    &.menu-mlid-712 > .links:before           { content: "\e60b";} /* Oorlogschirurgie */
    &.menu-mlid-713 > .links:before           { content: "\e610";} /* Toxicologie */
    &.menu-mlid-1102 > .links:before          { content: "\e604"; } /* Chem. Agentia */
    &.menu-mlid-1111 > .links:before          { content: "\e602"; } /* Bio Agentie */
  }
}


/* 1.3 medical breadcrumb
---------------------------------------------------------------------------- */
#medicalBreadcrumb,
#medicalBreadcrumb ul,
#medicalBreadcrumb li                                       { margin: 0; padding: 0; list-style: none; }

#medicalBreadcrumb                                          { margin-top: 15px !important; }
#medicalBreadcrumb > li                                     { position: relative; display: inline-block; width: 100%; margin: 0 10px 10px 0; }
#medicalBreadcrumb > li > a,
#medicalBreadcrumb > li > span                              { display: block; width: 100%; padding: 5px 40px 5px 10px; background: url(../img/spriteIcons.png) no-repeat 100% -332px $colorBlueDarker; color: #fff; text-decoration: none; }
#medicalBreadcrumb > li > span                              { background-color: $colorGreyLight3; }
  #medicalBreadcrumb > li > a:hover,
  #medicalBreadcrumb > li > span:hover                      { background-color: $colorBlueDarkerHover; background-position: 100% -432px; }
  #medicalBreadcrumb > li > span:hover                      { background-color: $colorGrey; cursor: pointer; }

/* siblings */
#medicalBreadcrumb > li > ul                                { display: none; position: absolute; top: 34px; left: 0; padding: 10px 15px; border: solid 2px $colorGreyLight2; background: #fff; z-index: 999; }
#medicalBreadcrumb > li > ul a                              { display: block; padding: 2px 0; text-decoration: none; color: $colorDefault; }
  #medicalBreadcrumb > li > ul a:hover,
  #medicalBreadcrumb > li > ul a.active-trail               { color: $colorBlueHover; }

/* level 0 */
#medicalBreadcrumb .level0 > a                              { padding-right: 10px; background: $colorGreyLight3; }
  #medicalBreadcrumb .level0 > a:hover                      { background: $colorGrey; }

/* level 1 */
#medicalBreadcrumb .level1 > a                              { background-color: $colorBlue; }
  #medicalBreadcrumb .level1 > a:hover                      { background-color: $colorBlueHover; }

/* level 2 */
#medicalBreadcrumb .level2 > a                              { background-color: $colorBlueDark; }
  #medicalBreadcrumb .level2 > a:hover                      { background-color: $colorBlueDarkHover; }

/* level 3 */
#medicalBreadcrumb .level3 > a                              { background-color: $colorBlueDarker; }
  #medicalBreadcrumb .level3 > a:hover                      { background-color: $colorBlueDarkerHover; }


@include bp($tablet-narrow) {
  #medicalBreadcrumb > li                                   { width: auto; }
}

/*
###############################################################################
##
## @file:         inc.pages.scss

## @description:  Defines the page styling for the website
##
###############################################################################


[Table of contents]

1. PAGES
    1.1 print



###############################################################################
*/


/* =========================================================================
 * 1. PAGES
 * ========================================================================= */

/* 1.1 print
---------------------------------------------------------------------------- */
.print-body                           { text-align: left; font-size: 0.8em; }


/* 1.2 homepage
---------------------------------------------------------------------------- */
/* .front                                {
  .embedded-video                       { margin: 0 0 3em; }
  @include bp($tablet-narrow)  {
    .embedded-video,
    .main-content                         { float: left; width: 48%; margin-left: 2%; }
    .embedded-video                       { margin: 0 2% 0 0; }
  }
} */


/* 1.3 medical pages
---------------------------------------------------------------------------- */
#toc                                  { margin-bottom: 2em; font-size: 0.875em;
  ul, li                                { padding: 0; margin: 0; list-style: none; }
  li                                    { line-height: 2em; }
  .h3                                   { margin-left: 1.75em; list-style-type: disc; }
  .h4                                   { margin-left: 3.5em; }
  a                                     { text-decoration: none; white-space: normal; }
}
.node-type-medical-page               {
  .toc                                  { padding: 0 1em; }
}


/* 1.4 contact page
---------------------------------------------------------------------------- */
.page-node-6                          {
  @include bp($tablet-narrow)           {
    .body                                 { overflow: hidden;
      .block-system,
      .block-webform                        { width: 48%; float: left; }
      .block-webform                        { float: right; }
    }
  }
  .webform-client-form                  {
    label                                 { display: none; }
    input.form-text                       { width: 100%; }
  }
}


/* 1.5 blocks
---------------------------------------------------------------------------- */
/* ----- medical link ----- */
.link-medical-pages           { display: block; text-decoration: none;
  &__img                        { display: block; width: 100%; height: auto; }
}

/* ----- recent news ----- */
.block-views-news-block       { margin-bottom: 20px; border: 1px solid $color-brand-blue-regular; font-family: $font-narrow; @include x-rem(font-size, 1);
  h2                            { margin: 0; padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); text-transform: uppercase; font-weight: 400; color: $color-white; background-color: $color-brand-blue-regular;  }
  > .content                    { padding: 15px; }
  .more-link a                  { font-size: .875em; }
}
.news-block                   {
  &__item                       { overflow: hidden; padding-bottom: 15px; margin-bottom: 15px; border-bottom: 1px solid $color-brand-blue-regular; }
  &__title                      { margin: 0 0 .125em; line-height: 1; font-size: .938em; font-weight: 700; color: $color-default; }
  &__date                       { display: block; line-height: 1; margin-bottom: .5em; font-size: .813em; color: $color-brand-blue-regular; }
  &__body                       { font-size: .875em; }
  &__link                       { display: block; float: right; font-size: .875em; padding: 0 5px; line-height: 30px; text-decoration: none; background-color: $color-brand-blue-regular; color: $color-white;
    &:hover,
    &:focus                       { background-color: $color-default; color: $color-white; }
  }
}
/* News Filter */
.page-nieuws .views-exposed-form  { border: 1px solid #d1d3d4; padding: 10px; margin-bottom: 40px;
  .views-exposed-widget         { float: left; }
  .views-widget-filter-name_list{
    label, .views-widget          { float: left; }
    label                         { padding: 0; line-height: 32px; }
    .form-item                    { margin: 0 1em; }
  }
}

/* ----- recent vacancies ----- */
.block-views-vacancies-block-recent { margin-bottom: 20px; border: 1px solid $color-brand-blue-var1; font-family: $font-narrow; @include x-rem(font-size, 1);
  h2                            { margin: 0; padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); text-transform: uppercase; font-weight: 400; color: $color-white; background-color: $color-brand-blue-var1;  }
  > .content                    { padding: 0 0 15px; }
  ul, li                        { display: block; margin: 0; padding: 0; }
  ul                            { padding-bottom: 15px; }
  li                            { padding: 0 15px;
    a                             { display: block; overflow: hidden; padding: 10px 0; line-height: 30px; font-size: .938em; font-weight: 700; border-bottom: 1px solid $color-brand-blue-var1; color: $color-default; text-decoration: none; white-space: normal; }
    &:hover,
    a:hover,
    a:focus                       { background-color: mix($color-brand-blue-var1, $color-white, 20%); }
  }
  .vacancies-block__location    { display: block; font-weight: 400; line-height: 1; font-size: .875em; color: $color-brand-blue-var1; }
  .more-link a                  { margin-right: 15px; font-size: .875em; color: $color-brand-blue-var1; }
  @include bp($tablet-narrow)   {
    .vacancies-block__location    { width: 30%; float: right; padding: 5px; line-height: 20px; }
  }
}

/* ----- medical updates ----- */
.block-views-pages-block-1    { border: 1px solid $color-brand-blue-var2; font-family: $font-narrow; @include x-rem(font-size, 1);
  h2                            { margin: 0; padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); font-weight: 400; color: $color-white; background-color: $color-brand-blue-var2;  }
  ul, li                        { display: block; margin: 0; padding: 0; }
  li                            {
    a                             { display: block; padding: 10px 15px; line-height: 30px; font-size: .938em; color: $color-default; text-decoration: none; white-space: normal; }
    &:nth-child(even) a           { background-color: mix($color-brand-blue-var2, $color-white, 10%); }
    a:hover,
    a:focus                       { background-color: mix($color-brand-blue-var2, $color-white, 30%); }
  }
  .badge__new                   { display: block; float: right; font-size: .875em; padding: 0 5px; background-color: $color-brand-blue-var2; color: $color-white; }
}


/* 1.6 Vacancies pages
-----------------------------------------------------------------------------*/
/* ----- vacancies overview ----- */
.vacancies-listing            { font-family: $font-narrow; @include x-rem(font-size, 1); border-top: 1px solid $color-brand-blue-var1;
  &, li                         { display: block; margin: 0; padding: 0; }
  li                            {
    a                             { display: block; padding: 10px; line-height: 30px; font-size: .938em; font-weight: 700; border-bottom: 1px solid $color-brand-blue-var1; color: $color-default; text-decoration: none; white-space: normal; }
    a:hover,
    a:focus                       { background-color: mix($color-brand-blue-var1, $color-white, 20%); }
  }
  &__location                   { display: block; font-weight: 400; line-height: 1; font-size: .875em; color: $color-brand-blue-var1;
    @include bp($tablet-narrow)   { width: 30%; float: right; padding: 0 5px; line-height: 30px; }
  }
}


/* 1.7 News pages
-----------------------------------------------------------------------------*/
/* ----- detail page ----- */
.node-type-news,
.node-type-rss-item           {
  .date-display-single          { display: block; margin-bottom: 20px; font-family: $font-narrow; font-weight: 700; }
  .news__link                   { display: block; float: right; font-size: .875em; margin-bottom: 20px; padding: 0 5px; line-height: 30px; text-decoration: none; background-color: $color-brand-blue-regular; color: $color-white;
    &:hover,
    &:focus                       { background-color: $color-default; color: $color-white; }
  }
}


/* 1.8 sidebar video's, links & recommended blocks
-----------------------------------------------------------------------------*/
/* ----- video's ----- */
.video-block                  { border: 1px solid $color-brand-blue-regular; color: $color-default; padding-bottom: 15px;
  &__title                      { padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); margin: 0; color: $color-white; background-color: $color-brand-blue-regular; }
  &__video                      { overflow: hidden; padding: 15px 15px 0;
    > a                           { display: block; width: 82px; height: 82px; float: left; position: relative; border: 1px solid $color-default;
      &:before                      { @include x-icon($icon-play); display: block; position: absolute; width: 80px; height: 80px; top: 0; left: 0; line-height: 80px; font-size: 40px; background-color: rgba($colorDefault, 0.4); color: $color-white; text-align: center; }
      &:hover, &:focus              { box-shadow: 0 0 10px $color-brand-blue-regular;
        &:before                      { background-color: rgba($color-brand-blue-var2, 0.6); }
      }
    }
    .video-embed-description      { min-height: 82px; padding-left: 100px; clear: right; }
  }
}

/* ----- links ----- */
.links-block                  { border: 1px solid $color-brand-blue-var2; color: $color-default;
  &__title                      { padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); margin: 0; color: $color-white; background-color: $color-brand-blue-var2; }
  &__links                      {
    a                             { display: block; padding: 10px 15px; line-height: 30px; font-size: .938em; color: $color-default; text-decoration: none; white-space: normal; }
    a:nth-child(even)             { background-color: mix($color-brand-blue-var2, $color-white, 10%); }
    a:hover,
    a:focus                       { background-color: mix($color-brand-blue-var2, $color-white, 30%); }
    a:after                       { @include x-icon($icon-right-arrow); padding-left: .25em; }
  }
}

/* ----- recommended ----- */
.recommended-block            { border: 1px solid $color-brand-blue-var1; color: $color-default;
  &__title                      { padding: 0 15px; line-height: 50px; @include x-rem(font-size, 1.063); margin: 0; color: $color-white; background-color: $color-brand-blue-var1; }
  &__body                       { padding: 15px; }
}


/* 1.9 banner blocks
-----------------------------------------------------------------------------*/
.banner-block                 { border: 1px solid $color-lightgrey;
  a                             { display: block; text-align: center; }
  img                           { display: inline-block; max-width: 100%; height: auto; }
}

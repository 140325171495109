/*
###############################################################################
##
## @file:         _misc.scss
## @description:  Defines the miscellaneous styling for the website
##
###############################################################################
*/

/**
 * Drupal messages & console
 * ------------------------------------------------------------------------- */
.messages               { position: relative; margin-bottom: 1.5em; padding: 1.25em 1em 1.25em 4em; color: #444; @include x-rem(font-size, 0.813); text-align: left; background-color: lighten($color-status, 42%); border: 1px solid $color-status;
  &:before                { content: "\279C"; position: absolute; left: .5em; top: .5em; width: 1em; text-align: center; font-size: 2em; line-height: 1em; color: $color-status; }

  &.status                { background-color: lighten($color-success, 42%); border-color: $color-success; }
  &.status:before         { content: "\2714"; color: $color-success; }
  &.warning               { background-color: lighten($color-warning, 42%); border-color: $color-warning; }
  &.warning:before        { content: "\25b2"; color: $color-warning;; }
  &.error                 { background-color: lighten($color-error, 42%); border-color: $color-error; }
  &.error:before          { content: "\2716"; color: $color-error; }

  h2                      { font-size: 1em; margin: 0 0 .5em; }
  .placeholder            { font-weight: 700; }
  p, ul, ol               { margin-top: 0; }
  p:last-child,
  ul:last-child,
  ol:last-child           { margin-bottom: 0; }
}


/**
 * Cookie message
 * ------------------------------------------------------------------------- */
#sliding-popup              { display: block; z-index: 99999; background-color: $color-default; background-color: rgba($color-default, 0.8);
  .popup-content              { padding: 1em; @include x-rem(font-size, 0.813); @include x-rem(max-width, 70); }
  .popup-text                 { color: #fff;
    p                           { margin-bottom: 1em; text-align: center; }
  }
  .popup-buttons              {
    button                      { display:inline-block; margin-bottom: 0.5em; padding: 0 1em; line-height: 3em; cursor: pointer; background-color: transparent; border:1px solid $color-white; color: $color-white; }
    .more-info                  { }
    .agreed                     { background-color: $color-white; color: $color-default; }

    button:hover, button:focus  { background-color: $color-highlight; color: $color-white;}
    button:active               { position: relative; top: 1px; }
  }

  @include bp($tablet_wide)   {
    .popup-text                 { float: left; width: 60%; line-height: 2em; padding: 0.5em 0;
      p                           { margin: 0; text-align: left; }
    }
    .popup-buttons              { float: right; width: 40%; text-align: right;
      button                      { margin-left: 0.5em; line-height: 2.5em; }
    }
  }
  @include bp($desktop)       {
    .popup-text                 { width: 60%; line-height: 2.5em; padding: 0;
      p                           {}
    }
    .popup-buttons              { width: 40%;
      button                      { margin-bottom: 0; }
    }
  }
}
.sliding-popup-bottom       { position: fixed; bottom: 0; }


/**
 * More links
 * ------------------------------------------------------------------------- */
.more-help-link,
.more-link                  { text-align: right;
  a                           { display: inline-block; margin: 0; padding: 0; border: 0 none; line-height: 1.5; font-family: $font-narrow; font-size: 700; color: $color-brand-blue-regular; text-decoration: none; font-size: 1em; transition: color .3s ease;
    &:after                    { @include x-icon($icon-right-arrow); padding-left: .5em; }
    &:hover, &:focus           { color: $color-default; }
  }
}


/**
 * Pagination
 * ------------------------------------------------------------------------- */
ul.pager                { display: block; margin: 20px 0; border-radius: 4px; text-align: center; line-height: 2;
  &, li                   { list-style: none; padding: 0; }
  li                      { display: inline-block; vertical-align: top; margin: 0; margin-left: -5px; }
  .pager-ellipsis         { color: $color-default; cursor: not-allowed; border-color: $color-lightgrey; padding: 1px 14px; }
  .pager-current          { z-index: 2; color: $color-white; cursor: default; background-color: $color-highlight; padding: 1px 14px; }

  a, span                 { position: relative; float: left; padding: 0 13px; color: $color-highlight; text-decoration: none; background-color: $color-white; border: 1px solid $color-lightgrey;
    &:hover,
    &:focus                 { z-index: 3; color: $color-highlight; background-color: $color-lightgrey; border-color: $color-lightgrey; }
  }
  .pager-first a,
  .pager-first span       { margin-left: 0; border-radius: 4px 0 0 4px; }
  .pager-last a,
  .pager-last span        { border-radius: 0 4px 4px 0; }
}


/**
 * Breadcrumbs
 * ------------------------------------------------------------------------- */
.breadcrumbs            { margin-bottom: 1.5em; @include x-rem(font-size, 0.813);
  a                       { color: $color-default; text-decoration: underline; }
  a:hover,
  a:focus                 { color: $color-highlight; }
  span                    { color: lighten($color-default, 40%); }
}


/**
 * Progress bar
 * ------------------------------------------------------------------------- */
.progress               { position: relative;
  .bar                    { height: 20px; margin-bottom: 1em; overflow: hidden; background-color: $color-lightgrey; border-radius: 4px; box-shadow: inset 0 1px 2px rgba(0,0,0,.1); }
  .filled                 { width: 0; height: 100%; font-size: 12px; line-height: 20px; color: #fff; text-align: center; background-color: $color-highlight; transition: width .6s ease; }
  .percentage             { position: absolute; top: 0; width: 100%; line-height: 20px; color: #fff; text-align: center; font-size: 14px; text-shadow: 1px 1px 1px rgba(0,0,0,.8);  }
}


/**
 * Lazysizes
 * ------------------------------------------------------------------------- */
.lazyload,
.lazyloading                { opacity: 0; }
.lazyloaded                 { opacity: 1; transition: opacity 0.3s ease; }


/**
 * Misc
 * ------------------------------------------------------------------------- */
/* drupal console */
#console                { margin: 0; width: auto;}

/* Unpublished nodes */
body.unpublished:after  { position: fixed; display: block; bottom: 0; width: 100%; padding: 10px; line-height: 1em; content: "Not published!"; font-size: 14px; font-weight: bold; text-shadow: 1px 1px 5px rgba($color-black, 0.8); color: $color-white; background-color: rgba($color-warning, 0.8); }

.slicknav_menu          { position: fixed; top: 0; left: 0; }


/**
 * Contextual styling
 * ------------------------------------------------------------------------- */
/* Contextual links regions */
.contextual-links-region      { outline: none; position: relative; }
.contextual-links-region-active { outline: #999 dashed 1px; }

/* Contextual links */
div.contextual-links-wrapper  { display: none; font-size: 90%; position: absolute; right: 5px; top: 2px; z-index: 999;
  html.js &                   { display: block; }
}
a.contextual-links-trigger    { background: transparent url(../img/gear-select.png) no-repeat 2px 0; border: 1px solid transparent; display: none; height: 18px; margin: 0; padding: 0 2px; outline: none; text-indent: 34px; width: 28px; overflow: hidden; border-radius: 4px;
  &:hover,
  div.contextual-links-active &  { background-position: 2px -18px; }
  div.contextual-links-active &  { background-color: #fff; border-color: #ccc; border-bottom: none; position: relative; z-index: 1; border-radius: 4px 4px 0 0; }
}
div.contextual-links-wrapper  {
  ul.contextual-links           { background-color: #fff; border: 1px solid #ccc; display: none; margin: 0; padding: 0.25em 0; position: absolute; right: 0; text-align: left; top: 18px; white-space: nowrap; border-radius: 4px 0 4px 4px; }
}
a.contextual-links-trigger-active,
div.contextual-links-active a.contextual-links-trigger,
div.contextual-links-active ul.contextual-links     { display: block; }
ul.contextual-links li        { line-height: 100%; list-style: none; list-style-image: none; margin: 0; padding: 0; }
div.contextual-links-wrapper a{ text-decoration: none; }
ul.contextual-links li a      { color: #333 !important; display: block; margin: 0.25em 0; padding: 0.25em 1em 0.25em 0.5em; }
ul.contextual-links li a:hover{ background-color: #bfdcee; }

/*
###############################################################################
##
## @file:         inc.colors.less
## @description:  Defines the colors for the website
##
###############################################################################
*/

/**
 *  default color definitions - only change values, do not rename!
 * ------------------------------------------------------------------------- */
$color-default: #4d4d4f;
$color-highlight: #0c8ab8;

$color-white: #fff;
$color-lightgrey: #d1d3d4; /* used for borders, hr, fields, etc */
$color-midgrey: #939598;
$color-black: #000;

$color-link: #0c8ab8;
$color-link-hover: #00aeef;

$color-bg-default: #fff;

/* selection colors */
$color-selected-text: $color-white;
$color-selected-text-bg: $color-link-hover;

/* status & validation */
$color-status: #8ed9f6;
$color-success: #36c14d;
$color-warning: #ffbf00;
$color-error: #c43838;


/**
 * custom color definitions
 * ------------------------------------------------------------------------- */
// original color settings
$colorDefault: $color-default;
$colorHighlight: $color-highlight;

$colorGrey: #707070;
$colorGreyLight: #fbf9f9;
$colorGreyLight2: #e0e0e0;
$colorGreyLight3: #a8a8a8;
$colorBlue: #02abe3;
$colorBlueHover: #17bbf2;
$colorBlueDark: #1a79b0;
$colorBlueDarkHover: #3896cd;
$colorBlueDarker: #0e3b54;
$colorBlueDarkerHover: #235773;

// new color settings
$color-brand-blue-regular: $color-highlight;
$color-brand-blue-var1: #00aeef;
$color-brand-blue-var2: #00badc;

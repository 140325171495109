/*
###############################################################################
##
## @file:         _variables.scss
## @description:  Defines some global variables for the website
##
###############################################################################
*/

/**
 * Variables
 * ------------------------------------------------------------------------- */
//$polyfill-path: "/sites/all/themes/medics4medics/assets/css/polyfills/";
$icomoon-font-path: "../fonts/icomoon/fonts";


/**
 * Breakpoints
 * ------------------------------------------------------------------------- */
$mobile-narrow: 320px;
$mobile-wide: 480px;
$tablet-narrow: 720px;
$tablet-wide: 912px;
$desktop: 1120px;
$desktop-large: 1440px;
$wide: 1600px;

// Switch from mobile to desktop nav
$nav-switch: $tablet-narrow;


/**
 * Icons
 * ------------------------------------------------------------------------- */
$icon-close: "\e908";
$icon-menu: "\e909";
$icon-locked: "\e902";
$icon-avatar: "\e900";
$icon-profile: "\e904";
$icon-magnifying-glass: "\e903";
$icon-search2: "\e907";
$icon-play: "\e906";
$icon-left-arrow: "\e901";
$icon-right-arrow: "\e905";
$icon-BrokenBone: "\e600";
$icon-AdvancedLifeSupport: "\e601";
$icon-BiologischeOorlogsagentia: "\e602";
$icon-Cardiologie: "\e603";
$icon-Chemische-oorlogs-agentia: "\e604";
$icon-GynecologieVerloskunde: "\e605";
$icon-Infectiezaken: "\e606";
$icon-Medicatie: "\e607";
$icon-MedischeAnalysen: "\e608";
$icon-MetaboleZiekten: "\e609";
$icon-Neurologie: "\e60a";
$icon-Oorlogschirurgie: "\e60b";
$icon-Pediatrie: "\e60c";
$icon-Pneumologie: "\e60d";
$icon-Rampenmanagement: "\e60e";
$icon-Symptomen: "\e60f";
$icon-Toxicologie: "\e610";
$icon-Traumatologie: "\e611";

/*
###############################################################################
##
## @file:         _helpers.scss
## @description:  Defines the helper classes for the website
##
###############################################################################
*/

/**
 * helper classes
 * ------------------------------------------------------------------------- */
.hidden                             { display: none !important; visibility: hidden; }
.invisible                          { visibility: hidden; }

.element-invisible,
.visuallyhidden                     { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus,
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus     { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

.clearfix:before,
.clearfix:after                     { content: " "; display: table; }
.clearfix:after                     { clear: both; }
.clearfix                           { *zoom: 1; }

@charset "UTF-8";

/*
###############################################################################
##
## @file:         main.scss
## @description:  Defines main styling for the website
##
##                This file is compiled into main.css
##
###############################################################################
*/


/* ---------- Import base ---------- */
@import "global/init";
@import "vendor/normalize";

/* ---------- Import components ---------- */
@import "components/base";
@import "components/layout";
@import "components/forms";
@import "components/nav";
@import "components/modifiers";
@import "components/misc";
@import "components/general";
@import "components/specific";

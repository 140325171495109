/*
###############################################################################
##
## @file:         inc.forms.less
## @description:  Defines the form styling for the SITENAME website
##
###############################################################################


[Table of contents]

1. FORMS
    1.1 search form
    1.2 comment form
    1.3 header search form


###############################################################################
*/

/* =========================================================================
 * 1. FORMS
 * ========================================================================= */
/* webkit form elements */
input,
textarea,
select                          { border: 1px solid $colorGreyLight2; padding: 5px; background: #fff; border-radius: 0; color: $colorDefault; font-family: inherit; font-size: 1em; }
  input:focus,
  textarea:focus,
  select:focus                  { outline: none; }
  select:hover,
  option:hover                  { cursor: pointer; }

.form-submit                    { padding: 7px 15px; margin-right: 10px; background: $colorBlue; border: none; color: #fff; }
  input:hover.form-submit       { background: $colorBlueDark; }

/* placeholders */
::placeholder {
  color:    $color-midgrey;
  opacity:  1;
}


/* 1.1 search form
---------------------------------------------------------------------------- */
#block-views-exp-search-page                  { padding: 20px; border: solid 1px $colorGreyLight2; }
#block-views-exp-search-page .form-text,
#block-views-exp-search-page .form-select     { width: 100%; }

@include bp($tablet-narrow) {
  #block-views-exp-search-page .form-text,
  #block-views-exp-search-page .form-select   { width: auto; }
}


/* 1.2 comment form
---------------------------------------------------------------------------- */
.comment-form-wrapper                     { margin-top: 50px; }
.comment-form                             { border: solid 1px $colorGreyLight2; padding: 20px; }


/* 1.3 header search form
---------------------------------------------------------------------------- */
.header-search                { font-size: .875em;
  form                          { position: relative; margin: 0; padding: 0; }
  .form-item-keywords           { display: inline-block; vertical-align: top; width: 185px; margin: 0; margin-right: -.25em; }
  .form-text                    { width: 100%; height: 34px; line-height: 24px; padding: 5px 0 5px 10px; background-color: $color-white; color: $color-default; border: solid 1px $color-default;
    &:hover,
    &:focus                       { border-color: $color-brand-blue-regular; }
    @include placeholder          { font-size: .857em; /* 12px */ }
  }
  .form-submit                  { display: inline-block; vertical-align: top; /* width: 34px; */ height: 34px; line-height: 34px; margin-right: -.25em; padding: 0 5px; border: 0 none; background-color: $color-default; }
}
/* .header-search .form-item                  { margin: 0; }
.header-search .form-text                  { width: 100%; padding: 9px; border: none; color: $colorBlue; font-weight: bold; }
.header-search .form-submit                { position: absolute; bottom: 0; right: 0; padding: 9px; width: 40px; margin: 0; background: url(../img/spriteIcons.png) no-repeat 11px 9px $colorBlue; text-indent: 999px; overflow: hidden; }
  .header-search input:hover.form-submit   { background-color: $colorBlueHover; } */


#user-register-form                       {
  legend                                    { font-weight: bold; }
  .container-inline-date .date-padding      { float: none; padding: 0; }
}


/* 1.4 form styling
---------------------------------------------------------------------------- */
form,
.webform-client-form          {
  legend                        { margin: 0 20px; padding: 0 10px; font-size: 1rem; color: $color-brand-blue-regular; font-family: $font-narrow; font-weight: 700; }
  label                         { display: block; padding-bottom: 5px; font-family: $font-narrow; font-weight: 700;
    &.option                      { display: inline; font-weight: 400; font-family: inherit; }
  }
  .webform-container-inline label { /* display: inline-block; */ min-width: 150px; padding-right: 10px;  }

  .form-text,
  .form-textarea                { width: 100%; }

  .description                  { margin-top: 5px; margin-bottom: 5px; font-size: .813rem; }
  .fieldset-description         { margin-bottom: 20px; }
  .form-required                { color: $color-brand-blue-var1; }
}
.form-type-textfield,
.form-type-select,
.form-type-emailfield,
fieldset.form-wrapper           { margin-bottom: 20px; }

.webform-component            {
  &-fieldset                    { border: 1px solid $color-midgrey; border-width: 1px 0 0 0; margin: 0; padding: 10px 0 20px;
    &.form-wrapper                { margin-bottom: 0; }
  }
  &-textfield,
  &-email,
  &-textarea                    { margin-bottom: 20px; }
}
.text-format-wrapper          { margin-bottom: 20px; }

.webform-client-form-410      {
  @include bp($tablet-wide)     {
    .webform-component-textfield,
    .webform-component-email      { width: 50%; float: left; padding-right: 10px;
      &:nth-child(odd)              { padding-left: 10px; padding-right: 0; }
    }
  }
}

/* google recaptcha */
.g-recaptcha                  { margin-bottom: 20px; }

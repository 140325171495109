/*
###############################################################################
##
## @file:         _layout.scss
## @description:  Defines the page structure for the website
##
###############################################################################
*/

.wrapper                      { position: relative; width: 96%; max-width: 1200px; margin: 0 auto; text-align: left; }

/* ----- header ----- */
.page-header                  {
  &__top                        { padding: 10px 0; }
}

/* .wrapperContent               { width: 95%; max-width: 1200px; min-width: 300px; padding: 2% 0; margin: 0 auto; text-align: left; } */

/* navigation */
#navWrapper                 { position: relative; background-color: #f7f7f7; background-image: linear-gradient(to bottom, #ffffff, #f7f7f7); z-index: 501; }
#navWrapper .wrapperContent { padding: 12px 0; }

/* navigation */
#medicalNavWrapper          { position: relative; /* background-color: #e8eff5; background-image: linear-gradient(to bottom, #fdfefe, #e8eff5); */ z-index: 601; }
#medicalNavWrapper .wrapperContent { /* padding: 40px 0 30px 0; */ }

/* content */
/* #contentWrapper               { position: relative; background: #fff; z-index: 701; } */

.page-content                 { position: relative; margin: 0 auto; padding: 20px 0 40px; text-align: left;
  &__body                       { }
  &__bottom                     { padding-top: 30px; }

  @include bp($tablet-narrow)   {
    .sidebar-aside &              { width: 66.665%; float: left; padding-right: 10px; padding-bottom: 100px; }
  }
}

/* aside */
.page-aside                   { padding: 20px 0 100px;
  > .block                      { margin-bottom: 20px; }
  @include bp($mobile-wide)     { position: relative; margin: 0 -2%;
    > .block                      { width: 46%; float: left; margin-left: 2%; margin-right: 2%; }
  }
  @include bp($tablet-narrow)   { width: 33.332%; float: right; margin: 0; padding-left: 10px;
    > .block                      { width: 100%; float: none; margin-left: 0; margin-right: 0; }
  }
}


/* medical content */
/* @include bp($tablet-wide) {
  .page_content.has-aside   { float: left; width: 66%; }
  .page_aside               {float: right; width: 33%; padding: 3em 0; }
  .pin-wrapper .page_aside  { float: none; width: 100%; padding: 0;
    &.sticky                { padding-top: 1em; }
  }
} */

.page-footer                  { clear: both;
  .footer                     { position: relative; padding: 30px 0; margin: 0 auto; text-align: left; }
  .footer .left               { float: left; }
  .footer .right              { float: right; }
}
